import {  
    Routes,
    Route
} from 'react-router-dom'

import Home from './pages/Home'
import LojasDisplay from './pages/Lojas'
import RestaurantesDisplay from './pages/Restaurantes'
import Cidades from './pages/Cidades'
import Ops from './pages/Ops'

export default function MainRoutes() {
    return (
        <Routes>
            <Route path="/"  element={<Home/>} />

            <Route path="/lojas"  element={<LojasDisplay/>} />

            <Route path="/restaurantes"  element={<RestaurantesDisplay/>}/>

            <Route path="/cidades/:estado" element={<Cidades/>} >   
                <Route path=":cidade" />
                <Route path=":cidade/:tipo" />
            </Route>

            <Route path="/ops/:estado" element={<Ops/>}>
                <Route path=":cidade" />
            </Route>

            <Route path="*" element={<Ops/>} />
        </Routes>
    )
}