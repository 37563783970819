import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Header = styled.header`
    background-color: #ffffff;
	width: 100%;
	height: 150px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

    img{
        width: 70%;
	    object-fit: contain;
    }

    h3{
        font-size: 1rem;
        font-weight: 500;
        margin-top: 6px;
    }

    @media (max-width: 768px){

    img{
        width: 70%;

    }
        
    }

`
export const LogoRound = styled(motion.div)`

    background: var(--green);
    width: 300px;
    height: 100%;
    border-radius: 0px 0px 175px 175px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px){
        width: 70%;
        
    }

`
export const LogoRoundOrange = styled(motion.div)`

    background: var(--orange);
    width: 300px;
    height: 100%;
    border-radius: 0px 0px 175px 175px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px){
        width: 70%;
        
    }
`