import styled from 'styled-components'

export const Container = styled.section`
	width: 100%;
	height: 35%;
	margin-top: 25px;
	margin-bottom: 35px;
	padding: 2%;

	display: flex;
	flex-direction: column;
	justify-content: center; 
	align-items: center;

    h3{
        color: #757575;
		font-size: 1rem;
		font-weight: 500;
	    margin-bottom: 8px;
    }

`

export const Estabelecimentos = styled.div`
    display: flex;
	flex-direction: row;
`