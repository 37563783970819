import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Text = styled(motion.section)`
    width: 100%;
	height: 15%;
    margin-top: 20px;
    padding: 0 8% 0;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1{
    font-size: 2rem;
    font-weight: 1000;
    text-align: center;
    margin-bottom: 15px;
    }

    h3{
    color: #757575;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    }
`