import { EmpresaList } from "../ListarEmpresas/EmpresasList";
import Grid from '@mui/material/Grid';

export function ShowEmpresas(params: { tipo: number }) {
    return (
         <Grid container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
            style={{ padding: '20px' }}
        >
            <EmpresaList tipo={params.tipo} />

        </Grid>
    )
}

