import { Container } from "./style"

import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
  SelectOption,
} from '@mui/base/SelectUnstyled';

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import OptionUnstyled from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { useNavigate,  useLocation } from 'react-router';
import { useState, useEffect } from 'react';
import { LocalProps, getLocais } from '../../models/localizacao';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled(motion.button)(
  ({ theme }) => `

  font-size: 1rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 320px;
  
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#f3f3f3'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : '#ffffff'};
  border-radius: 2rem;
  margin: 0.5em;
  padding: 10px;
  text-align: center;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : '#757575'};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; } 
    }
    @-moz-keyframes fadeIn {
    0% { opacity: 0;}
    100% { opacity: 1; }
    }
    @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }
    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    -webkit-animation: fadeIn 1s ease-in-out;
    -moz-animation: fadeIn 1s ease-in-out;
    -o-animation: fadeIn 1s ease-in-out;
    animation: fadeIn 1s ease-in-out;
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `

  font-size: 1rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 320px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  text-align: center;
  color: ${theme.palette.mode === 'dark' ? grey[300] : '#757575'};
  overflow: auto;
  outline: 0px;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }


  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect<TValue extends {}>(props: SelectUnstyledProps<TValue>) {
  const components: SelectUnstyledProps<TValue>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

function renderValue(option: SelectOption<number> | null) {
  if (option == null) {
    return <span><FmdGoodOutlinedIcon sx={{ float: 'left',  color: '#00d47f', fontSize: '21px' }} /> Escolha sua cidade...</span>;
  }

  return (
    <span>
    <FmdGoodOutlinedIcon sx={{ float: 'left', color: '#00d47f', fontSize: '21px'}}/> {option.label} 
    </span>
  );
}

export default function BuscarCidadePro(props:{tipo?:number}) {
  const navigate = useNavigate()

  const [locais, setLocais] = useState<LocalProps[]>();
    
  useEffect(() => {
      const carregaLocal = async () => {
        setLocais(await getLocais());
    }

    carregaLocal();

  }, [])

  return (
    
    <Container>
            
            <CustomSelect renderValue={renderValue} >
            
            {locais?.map((local, index) => (
                <p onClick={() => navigate(`/cidades/${local.estado}/${local.cidade}${props.tipo ? `/${props.tipo}` : ''}`)}>
                <StyledOption key={index} value={local.cidade} >                
                    {local.cidade}
                </StyledOption>
                </p> 
            ))}

            </CustomSelect>
    </Container>
    
  );
}
