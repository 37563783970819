import styled from "styled-components";

export const Container = styled.section`

	margin-top: 20px;
    padding-left: 15px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

    img{
        width: 22%;
        margin-bottom: 15px;
    }

    h3{
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 15px;
    }

    h4{
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 15px;
        text-align: center;
    }

    h5{
        color: var(--green);
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 15px;
        text-align: center;
    }

    a{
        background: var(--green);
        color: white;

        width: 50%;
        height: 34px;

        font-size: 1rem;

        margin: 10px 0 20px 0;
        padding: 3px 9px;
        border-radius: 20px;

        font-weight: bold;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }
`