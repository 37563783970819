import restaurantesImg from '../../../assets/hamburguer.png'
import  { BoxRestaurante } from './styles'

import { useNavigate } from 'react-router'

export function Restaurantes() {
    const navigate = useNavigate()

    return (
        <BoxRestaurante onClick={() => navigate('/restaurantes')}>
            <img src={restaurantesImg}alt="Restaurantes" />
            <p>RESTAURANTES</p>
            <a >veja as opções</a>
        </BoxRestaurante>
    )

}