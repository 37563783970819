import { api } from "../services/api";
import { CatalogoProps } from "./catalogo";

export interface EmpresaProps {
    listCatalogos: CatalogoProps[];
    id_empresa: number;
    nome_empresa: string;
    tipo_empresa: number;
    codigo_empresa: string;
    logo_empresa: string;
    cidade_end_empresa: string;
    html_url: string;
}


const getEmpresas = async (query = {}): Promise<EmpresaProps[] | []> => {
    return await api.get(`empresas`, {
        params: query
    }).then(response => {
        return response.data.listEmpresas;
    });
}


export { getEmpresas };