import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { style, Typography, Close, Catalogos, CatalogosBox, GreenLink, OrangeLink } from './styles';
import { dominio_base, protocol } from '../../services/api';
import { CatalogoProps } from '../../models/catalogo';
import { EmpresaProps } from '../../models/empresa';


export default function PopupCatalogo(props:
  {
    list_catalogos: CatalogoProps[],
    empresa: EmpresaProps
  }) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { empresa, list_catalogos } = props;

  return (


    <div >

      <img
        onClick={handleOpen}
        src={`${protocol}${empresa.codigo_empresa}.${dominio_base}/storage/Empresas/${empresa.id_empresa}/${empresa.logo_empresa}`}
        alt="lojas"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `/img/picture.svg`;
        }}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Fade in={open}>
          <Box sx={style}>

            <Close >
              <CloseIcon onClick={handleClose} fontSize="large" />
            </Close>

            <Typography>
              {empresa.nome_empresa}
            </Typography>

            <Typography>
              Selecione o catálogo a ser aberto
            </Typography>

            <Catalogos>

              {list_catalogos?.map((catalogo: CatalogoProps) => {

                var buttonLoja = (<></>)
                if (catalogo.horarioAtendimento == 1) {
                  buttonLoja = (
                    <GreenLink>
                      <CheckIcon />Aberto
                    </GreenLink>
                  )
                } else if (catalogo.horarioAtendimento == 2) {
                  buttonLoja = (
                    <OrangeLink>
                      <CheckIcon />Aceitando Pedidos
                    </OrangeLink>
                  )
                } else {
                  buttonLoja = (
                    <OrangeLink>
                      <CloseIcon />Fechado
                    </OrangeLink>
                  )
                }

                return (
                  <a href={`${protocol}${empresa.codigo_empresa}.${dominio_base}/${catalogo.codigo_catalogo}`} target="_blank" rel="noreferrer">
                    <CatalogosBox key={catalogo.id_catalogo}>
                      <img
                        src={`${protocol}${empresa.codigo_empresa}.${dominio_base}/storage/Empresas/${empresa.id_empresa}/Catalogos/${catalogo.id_catalogo}/${catalogo.imagem_catalogo}`}
                        width="100%"
                        height="auto"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `/img/picture.svg`;
                        }}
                      />
                      <h4>{catalogo.nome_catalogo}</h4>
                      {buttonLoja}
                    </CatalogosBox>
                  </a>
                )
              }
              )}

            </Catalogos>

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}