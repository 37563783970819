import { motion } from "framer-motion";
import styled from "styled-components";

export const LinkSpace = styled(motion.div)`
  background-color: #ffffff;
  width: 100%;
  height: auto;
  margin: 1%;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Aberto = styled.a`
  background: var(--green);
  color: white;
  font-size: 0.8rem;

  margin: 0;
  padding: 4px 8px;
  border-radius: 20px;

  font-weight: 700;
  cursor: pointer;
  position: fixed;
  top: 2px;
  right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Fechado = styled.a`
  background: var(--orange);
  color: white;
  font-size: 0.8rem;

  margin: 0;
  padding: 4px 8px;
  border-radius: 20px;

  font-weight: 700;
  cursor: pointer;
  position: fixed;
  top: 2px;
  right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LinkImg = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2.63px 2.63px rgb(0 0 0 / 15%);

  border-radius: 20px;
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1600px) {
    height: 230px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 180px;

    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    @media (min-width: 1600px) {
        max-height: 230px;
    }
  }
`;

export const LinkInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 7%;

  h4 {
    font-weight: 800;
    margin-bottom: 0.25rem;
  }
  img {
    width: 13px;
  }
`;
