import { Container } from "./styles";
import SadSmile from '../../assets/Smile.svg'

export function NaoExiste(){
    return(
        
		<Container >
			<img src={SadSmile} />
			
			<h3>Opss!</h3>

			<h4>Infelizmente nenhum cartaz encontrado!</h4>

			<h5>seja a primeira empresa a possuir um cartz na sua cidade!</h5>

			<a href="https://cartz.cartaz.digital/" target="_blank">Crie um Cartz</a>

		</Container>
	    
    )
}