import styled from "styled-components";

export const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '15px'
  };

export const Close = styled.div`
    width: 100%;
    height: 5%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

`

export const Typography = styled.h2`
    font-size: 1.3rem;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;

    &:nth-child(2){
    font-weight: bold;
    margin-bottom: 15px;
    }
`

export const Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    button{
        background: #A5A5A5;
        color: white;

        width: 40%;
        height: 34px;

        font-size: 1rem;

        margin: 25px 0 15px 0;
        padding: 3px 9px;
        border: none;
        border-radius: 20px;

        font-weight: bold;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:nth-child(2){
            background: var(--green);
        }
    }

`