import styled from 'styled-components'

export const Header = styled.header`
    background-color: #ffffff;
	width: 100%;
	height: 15vh;
	margin-top: 45px;
	padding: 10px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

    img{
        width: 18%;
	    object-fit: contain;
    }

    h3{
        font-size: 1rem;
        font-weight: 500;
        margin-top: 6px;
    }

    @media (max-width: 768px){

    img{
        width: 70%;

    }
        
    }
`