import logoImg from '../../assets/logo-cartz1.svg'

import { Header } from './styles'

import { useNavigate } from "react-router"
import { motion } from 'framer-motion'

export function HeaderHome() {

    return (
        <>
        <motion.div
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.8, delay: 0.3 }}
        >   
        <Header>

            <img src={logoImg} alt="Logo Cartz" />
            
            <motion.div
            initial={{ opacity: 0, scale: 1.2, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.7, delay: 0.5 }}
            >   
            <h3>encontre tudo que você precisa!</h3>
            </motion.div>
        </Header>
        </motion.div>

        </>

    )
}

export function HeaderCidade() {
    const navigate = useNavigate()

    return (
        <Header onClick={() => navigate('/')}>
            <img src={logoImg} alt="Logo Cartz" />
           
        </Header>
    )
}