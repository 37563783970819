import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.section)`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 50px 0;
  border-top: 2px solid rgb(213 213 213 / 40%);

  @media (max-width: 1360px) {
    width: 60%;
  }

  @media (max-width: 960px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  img {
    width: 164px;
    height: 123.67px;
  }

  @media (max-width: 768px) {
    padding: 4%;
  }
`;
export const Text = styled.div`
  width: 350px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: left;
  }

  h4 {
    color: #757575;
    font-weight: 500;
  }

  p {
    margin-bottom: 5px;
    font-weight: 700;
  }

  a {
    background-color: var(--green);
    color: white;

    width: 80%;
    margin-top: 15px;

    padding: 5px;
    border-radius: 20px;

    font-weight: bold;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
