import styled from "styled-components";

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "auto",
  maxHeight: "90%",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "15px",

  "@media(max-width: 768px)": {
    width: "95%",
  },
};

export const GreenLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 40px;
  background: var(--green);
  color: white;
  font-size: 1rem;
  text-align: center;
  margin: 25px 0 15px 0;
  padding: 3px 9px;

  border-radius: 20px;

  font-weight: 700;
  cursor: pointer;

  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
`;
export const OrangeLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 40px;
  background: var(--orange);
  color: white;
  font-size: 1rem;
  text-align: center;
  margin: 25px 0 15px 0;
  padding: 3px 9px;

  border-radius: 20px;

  font-weight: 700;
  cursor: pointer;

  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
`;

export const Close = styled.div`
  width: 100%;
  height: 5%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Typography = styled.h2`
  font-weight: normal;
  text-align: center;
  margin-top: 10px;

  &:nth-child(2) {
    font-weight: bold;
    margin-bottom: 15px;
  }
`;

export const Catalogos = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 25px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const CatalogosBox = styled.div`
  max-width: 100%;
  padding: 2%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 160px;
    height: 160px;
    background: cover;
  }

  h2 {
    margin-top: 15px;
  }

  button {
    background: var(--green);
    color: white;

    font-size: 1rem;

    margin: 25px 0 15px 0;
    padding: 3px 9px;
    border: none;
    border-radius: 20px;

    font-weight: bold;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const CatalogosImg = styled.div`
  background: #c4c4c4;
  width: 120px;
  height: 120px;
  border-radius: 20px;
`;
