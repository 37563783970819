import { HeaderCidade } from '../components/HeaderHome';
import BuscarCidadePro from '../components/Buscar';
import {  TextOps } from '../components/DivTexts';
import { NaoExiste } from '../components/Avisos';
import { useParams } from 'react-router';

export default function Ops(){
    let { cidade , estado } = useParams();

    return(
        <>
        <HeaderCidade />

        <BuscarCidadePro />

        <TextOps cidade={cidade} estado={estado}/>

        <NaoExiste />

        </>
    )
}
