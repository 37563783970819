import { EmpresasCidade } from "../Estabelecimento";

import { styled } from '@mui/system';

import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';
import { useState } from "react";

import { Grid } from "@mui/material";
import { useParams } from "react-router"
import { EmpresaProps, getEmpresas } from "../../models/empresa";

const TabStyled = styled(Tab)(
  `
  border-radius: 50px;
  min-height: 25px; 
  background-color: #e6e6e6;
  color: #393939 ;
  margin-left: 10px;
  font-weight: 600;

  `
)

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CidadeTabs() {

  let { cidade, tipo } = useParams();

  var formatType: number = tipo ? +tipo : 0;

  const [empresas, setEmpresas] = useState<EmpresaProps[]>([]);
  const [value, setValue] = React.useState(formatType);


  var query = {
    localizacao: `${cidade}`,
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  React.useEffect(() => {
    const carregaEmpresa = async () => {
      setEmpresas(await getEmpresas(query));
    }

    carregaEmpresa();

  }, [cidade])

  return (

    <Box sx={{ width: '100%', marginTop: '20px;' }}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered

          sx={{
            color: 'success.main',
            '& .Mui-selected': {
              backgroundColor: '#00D47F',
              color: '#ffffff',
            },
            '& .MuiTab-root': {
              color: '#393939',
            },
            '& .Mui-selected:nth-child(1)': {
              backgroundColor: '#00D47F',
              color: '#ffffff',
            },          
            '& .Mui-selected:nth-child(2)': {
              backgroundColor: '#00D47F',
              color: '#ffffff',
            },
            '& .Mui-selected:nth-child(3)': {
              backgroundColor: '#FF8759',
              color: '#ffffff',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },

          }}
        >
          <TabStyled label="Tudo" />
          <TabStyled label="Comércios" />
          <TabStyled label="Restaurantes" />         
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          style={{ padding: '20px' }}
        >
          <EmpresasCidade empresas={empresas} />
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          style={{ padding: '20px' }}
        >
          <EmpresasCidade empresas={empresas.filter((empresa: EmpresaProps) => empresa.tipo_empresa == 1)} />
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          style={{ padding: '20px' }}
        >
          <EmpresasCidade empresas={empresas.filter((empresa: EmpresaProps) => empresa.tipo_empresa == 2)} />
        </Grid>
      </TabPanel>      
    </Box>
  );
}

