import axios from 'axios';

//Alterar endereço ou porta conforme necessidade
var dominio_base = '', protocol = '';


if (process.env.NODE_ENV === 'development') {
  dominio_base = 'cartz.local';
  protocol = 'http://';
} else {
  dominio_base = 'cartaz.digital';
  protocol = 'https://';
}
// API REST
const api = axios.create({
  baseURL: `${protocol}${dominio_base}/api/portal`,
});


export { api, dominio_base, protocol };
