import lojaImg from '../../../assets/buy.png'
import  { BoxLoja } from './styles'

import { useNavigate } from 'react-router'

export function Lojas() {
    
    const navigate = useNavigate()

    return (
        <BoxLoja onClick={() => navigate('/lojas')}>
            <img src={lojaImg} alt="lojas" />
            <p>COMÉRCIOS</p>
            <a >veja as opções</a>
        </BoxLoja>
    )

}