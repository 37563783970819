import { HeaderHome } from '../components/HeaderHome';
import BuscarCidadePro from '../components/Buscar';
import { CallToAction } from '../components/Home/CtaHome';
import { EstabelecimentosHome } from '../components/Home/EstabelecimentosHome';
import PopupUserLocation from '../components/PopupLocation/GeoLocation';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

export default function Home() {
    const nCookies = new Cookies();
    const navigate = useNavigate();
    const [permiteLocalizacao, setPermiteLocalizacao] = useState(nCookies.get('permite_localizacao') as { response: Boolean });
    const [redireciona, setRedireciona] = useState(false);
    const [details, setDetails] = useState({ state: '', city: '' });

    useEffect(() => {
        const getUserGeolocationDetails = () => {
            fetch("https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0")
                .then(response => response.json())
                .then(data => {
                    setDetails(data);
                });
        }
        if (typeof (permiteLocalizacao) != 'undefined' && permiteLocalizacao.response) {
            getUserGeolocationDetails();
        }
    }, [permiteLocalizacao]);

    useEffect(() => {
        if (details.city != '' && details.state != '' && redireciona) {
            navigate(`/cidades/${details.state}/${details.city}`);
        }
    }, [details]);


    //nCookies.remove('permite_localizacao');
    console.log(redireciona);

    return (

        <>

            {typeof (permiteLocalizacao) == 'undefined' && (<PopupUserLocation setPermiteLocalizacao={setPermiteLocalizacao} setRedireciona={setRedireciona} />)}
            
            <HeaderHome />

            <BuscarCidadePro />

            <EstabelecimentosHome />

            <CallToAction />


        </>

    )
}