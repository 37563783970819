import logoWhiteImg from '../../assets/logo-cartz-white.png'

import logoOrangeImg from '../../assets/logo-cartz-white-orange.png'

import { Header, LogoRound, LogoRoundOrange,  } from './styles'
import { useNavigate } from "react-router"

export function HeaderDisplay() {
    const navigate = useNavigate()

    return (
        <Header onClick={() => navigate('/')}>
            <LogoRound
            initial={{ opacity: 0,  }}
            animate={{ opacity: 1  }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.7 }}
            >
            <img src={logoWhiteImg} alt="Logo Cartz" />
            </LogoRound>
        </Header>
    )
}

export function HeaderDisplayOrange() {
    const navigate = useNavigate()

    return (
        <Header onClick={() => navigate('/')}>
            <LogoRoundOrange
            initial={{ opacity: 0,  }}
            animate={{ opacity: 1  }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.7 }}
            >
            <img src={logoOrangeImg} alt="Logo Cartz" />
            </LogoRoundOrange>
        </Header>
    )
}