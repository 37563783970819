import { Text } from "./styles"

export function TextLoja(){
    return(
        <Text
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.4 }}
        >
            <h1>O que você precisa <br /> hoje?</h1>
            <h3>Conheça todas as lojas das mais diversas opções de produtos!</h3>
        </Text>
        
    )
}

export function TextRestaurante(){
    return(
        <Text
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.4 }}
        >
		<h1>Você tem fome <br /> de que?</h1>
		<h3>Navegue pelas diversas opções de restaurantes e food cartz que o Cartz pode oferecer</h3>
        </Text>
    )
}

export function TextCidade(props: any){
    return(
        <Text
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.4 }}
        >
        <h1>{props.cidade}</h1>
		<h3>{props.estado}</h3>
        </Text>
    )
}

export function TextOps(props: any){
    return(
        <Text
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.4 }}
        >
        <h1>{props.cidade}</h1>
		<h3>{props.estado}</h3>
        </Text>
    )
}