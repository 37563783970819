import { HeaderDisplay } from '../components/HeaderDisplay';
import { ShowEmpresas } from '../components/Display';
import { TextLoja } from '../components/DivTexts';
import BuscarCidadePro from '../components/Buscar';
import { CallToAction } from '../components/Home/CtaHome';


export default function LojasDisplay() {

    return (
        <>
            <HeaderDisplay />

            <TextLoja />

            <BuscarCidadePro tipo={1} />

            <ShowEmpresas tipo={1} />
            
            <CallToAction />
        </>
    )
}
