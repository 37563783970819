import { api } from "../services/api";

interface LocalProps {
    onClick(): import("react").ReactNode;
    id_empresa: number,
    cidade: string,
    uf:string,
    estado:string,
    localizacoes: string,
}


const getLocais = async (query = {}): Promise<LocalProps[] | []> => {
    return await api.get(`localizacoes`, {
        params: query
    }).then(response => {
        return response.data;
    });
}


export { getLocais };
export type { LocalProps };
