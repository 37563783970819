import { Lojas } from "../LojasHome"
import { Restaurantes } from "../RestaurantesHome"
import { Container, Estabelecimentos } from "./styles"

import { motion } from "framer-motion"
import { AnimateSharedLayout } from "framer-motion"

export function EstabelecimentosHome() {

    return(

        <Container>
            
            <motion.div
                    initial={{ opacity: 0, scale: 0.5, marginTop: 20 }}
                    animate={{ opacity: 1, scale: 0.95, marginTop: 0 }}
                    transition={{ duration: 0.8, delay: 1.2 }}
            >
                <h3>confira todos os estabelecimentos:</h3>
            </motion.div>

            <Estabelecimentos>
                <motion.div
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.9, delay: 1.4 }}
                >   
                    <Lojas></Lojas>
                </motion.div>    

                <motion.div
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1,  y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.9, delay: 1.4 }}
                >   
                    <Restaurantes></Restaurantes>
                </motion.div>  

            </Estabelecimentos>

        </Container>
        
    )
}