import styled from 'styled-components'

export const BoxRestaurante = styled.div`

    background-color: #F0F0F0;
    width: 184px;
    height: 215px;
    margin: 1%;
    border-radius: 14.5px;

    box-shadow: 0px 2.63px 2.63px rgba(0, 0, 0, 0.15);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

    img{
        width: 80%;
	    object-fit: contain;
    }

    p{
        color: var(--orange);
        font-size: 1.2rem;
        font-weight: bold;
    }

    a{
        background: var(--orange);
        color: white;

        width: 80%;
        height: 34px;

        font-size: 1rem;

        margin: 10px 0 20px 0;
        padding: 3px 9px;
        border-radius: 20px;

        font-weight: bold;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    @media(max-width: 768px){
        a{
            width: 75%;
        }
    }

`