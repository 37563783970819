import BuscarCidadePro from '../components/Buscar';
import { TextCidade } from '../components/DivTexts';
import CidadeTabs from '../components/CategoriaSelect';
import { useParams } from 'react-router';
import { CallToAction } from '../components/Home/CtaHome';
import { HeaderDisplay, HeaderDisplayOrange } from '../components/HeaderDisplay';

export default function Cidades() {
    let { cidade, estado, tipo } = useParams();

    var formatType: number = tipo ? +tipo : 1;

    return (
        <>

            {formatType == 2 ? (
                <HeaderDisplayOrange />
            ) : (
                <HeaderDisplay />
            )}


            <BuscarCidadePro />

            <TextCidade cidade={cidade} estado={estado} />

            <CidadeTabs />

            <CallToAction />

        </>
    )
}