import { LinkSpace, LinkImg, LinkInfo, Aberto, Fechado } from './styles'
import locationSvg from '../../assets/location.svg'
import PopupCatalogo from '../PopupCatalogo';
import { EmpresaProps } from '../../models/empresa';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

export function EmpresaItem(props: { empresa: EmpresaProps }) {
    const { empresa } = props;

    const horarioAtendimento = empresa.listCatalogos.some(catalogo => {
        return catalogo.horarioAtendimento < 3
    })
    return (
        <>

            <LinkSpace
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.4 }}
            >

                <LinkImg>

                    {(() => {
                        if (horarioAtendimento) {
                            return (
                                <Aberto><CheckIcon />Aberto</Aberto>
                            )
                        } else {
                            return (
                                <Fechado><CloseIcon fontSize="small" />Fechado</Fechado>
                            )
                        }
                    })()}

                    <PopupCatalogo
                        list_catalogos={props.empresa.listCatalogos}
                        empresa={props.empresa}
                    />

                </LinkImg>


                <LinkInfo>
                    <h4>{props.empresa.nome_empresa}</h4>
                    <p><img src={locationSvg} alt="Icone Localização" /> {props.empresa.cidade_end_empresa}</p>
                </LinkInfo>

            </LinkSpace>
        </>

    );
}

export function EmpresaCidadeAll(props: { empresa: EmpresaProps }) {
    
    const { empresa } = props;

    const horarioAtendimento = empresa.listCatalogos.some(catalogo => {
        return catalogo.horarioAtendimento < 3
    })

    return (
        <>

            <LinkSpace
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.4 }}
            >

                <LinkImg>

                    {(() => {
                        if (horarioAtendimento) {
                            return (
                                <Aberto><CheckIcon />Aberto</Aberto>
                            )
                        } else {
                            return (
                                <Fechado><CloseIcon fontSize="small" />Fechado</Fechado>
                            )
                        }
                    })()}

                    <PopupCatalogo
                        list_catalogos={props.empresa.listCatalogos}
                        empresa={props.empresa}

                    />

                </LinkImg>


                <LinkInfo>
                    <h4>{props.empresa.nome_empresa}</h4>

                    {(() => {
                        if (props.empresa.tipo_empresa == 1) {
                            return (
                                <p style={{ color: "#00D47F" }}><b>Comércios</b></p>
                            )

                        } else {
                            return (
                                <p style={{ color: "#FF8759" }}><b>Restaurante</b></p>
                            )
                        }
                    })()}

                </LinkInfo>

            </LinkSpace>
        </>


    );
}
