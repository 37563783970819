import ctaImg from '../../../assets/cartz-cart.png'
import { Container, Text } from "./styles"

export function CallToAction() {
    return (
        <Container
            initial={{ opacity: 0, scale: 0.95, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 1, delay: 1.6 }}

        >
            <img src={ctaImg} alt="cartz" />

            <Text>
                <h3>Ainda não possui Cartz?</h3>
                <h4>não perca a oportunidade de lançar seu negócio no meio online</h4>
                <p>conheça as vantagens e  comece ainda hoje!</p>
                <a style={{
                    border: "none",
                    outline: "none",
                    margin:'10px auto',
                    textAlign:'center'
                }} href="https://cartz.cartaz.digital/" target="_blank" rel="noreferrer">faça parte do cartz</a>
            </Text>
        </Container>
    )
}