import { useState, useEffect } from "react";
import { EmpresaItem } from "./Item";
import { EmpresaProps, getEmpresas } from "../../models/empresa";
import Grid from '@mui/material/Grid';

interface QueryParams{
    tipo?:number;
    localizacao?: string;
}

export function EmpresaList(props: QueryParams) {

    const [empresas, setEmpresas] = useState<EmpresaProps[]>();

    useEffect(() => {
        const carregaEmpresa = async () => {
            setEmpresas(await getEmpresas(props));
        }
        carregaEmpresa();

    }, [])
    
    return (
        <>
            {empresas?.map(empresa => {
                return (
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <EmpresaItem key={empresa.nome_empresa} empresa={empresa} />
                    </Grid>
                )
            })}
        </>
    );

}