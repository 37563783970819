import './styles/global.scss'


import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { AnimateSharedLayout } from 'framer-motion';

function App() {

  return (

    <Router>
      <AnimateSharedLayout>
        <Routes />
      </AnimateSharedLayout>

    </Router>
  );
}

export default App;