import { EmpresaProps } from '../../models/empresa';
import { EmpresaCidadeAll } from '../ListarEmpresas/Item';
import { Grid } from '@mui/material';
import { NaoExiste } from '../Avisos';

export function EmpresasCidade(props:{empresas:EmpresaProps[]}) {

    const {empresas} = props;
  
    if (empresas.length > 0) {
        return (
            <>

                {empresas?.map(empresa => {
                    return (
                        <Grid item xs={6} sm={4} md={3} lg={2} >
                            <EmpresaCidadeAll key={empresa.nome_empresa} empresa={empresa} />
                        </Grid>

                    )
                })}

            </>
        )

    } else {
        return (
            
            <NaoExiste />

        );
    }

}