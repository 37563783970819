import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'universal-cookie';

import { style, Typography, Buttons, Close } from './styles';

export default function PopupUserLocation(props: { setPermiteLocalizacao: Function, setRedireciona:Function }) {
  const cookies = new Cookies();
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
  };
  const handleSetCookie = (data: boolean) => {
    cookies.set('permite_localizacao', {response: data});
    props.setPermiteLocalizacao({response: data});
    props.setRedireciona(true);
    handleClose()
  }

  return (
    <div>
      <Button onClick={handleOpen}></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>

            <Close >
              <CloseIcon onClick={handleClose} fontSize="large" />
            </Close>

            <Typography>
              Permita que sua localização seja  compartilhada
            </Typography>

            <Typography>
              Assim exibiremos de forma automatica os cartazes na sua cidade!
            </Typography>

            <Buttons>

              <button onClick={() => {
                handleSetCookie(false);
              }}> NÃO</button>
              <button onClick={() => handleSetCookie(true)}> OK</button>
            </Buttons>

          </Box>
        </Fade>
      </Modal>
    </div>
  );

}