import { motion } from 'framer-motion'
import styled from 'styled-components'
  

export const Container = styled(motion.section)`

	width: 100%;
	height: 10vh;
	margin-top: 10px;
	padding: 2%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	p{
		width: 100%;
		height: auto;
	}
`

export const Buscar = styled.section`

    background-color: #f3f3f3;
	width: 20%;
	height: 34px;
	margin-top: 10px;
	border-radius: 25px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

    @media(max-width: 768px){

		width: 82%;
		padding: 2%;

        input{
            width: 100%;
        }
    }

`
