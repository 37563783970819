import { HeaderDisplayOrange } from '../components/HeaderDisplay';
import BuscarCidadePro from '../components/Buscar';
import {  ShowEmpresas } from '../components/Display';
import { TextRestaurante } from '../components/DivTexts';
import { CallToAction } from '../components/Home/CtaHome';

export default function RestaurantesDisplay(){

    return(

        <>
        <HeaderDisplayOrange />

        <TextRestaurante />

        <BuscarCidadePro tipo={2} />

        <ShowEmpresas tipo={2} />

        <CallToAction />

        </>

    )
}